<template>
	<div class="tcontainer">

		<div class="clearfix ttitle">
			<button @click="showAddEdit()" class="pull-right btn btn-success"><i class="fa fa-plus"></i></button>
			<div class="title">Manage Agents</div>
		</div>
		<DataTable stripedRows :value="tableRecords" :lazy="true" :resizableColumns="true" columnResizeMode="fit"
			:paginator="true" :rows="100" v-model:filters="filters" ref="dt" :totalRecords="totalRecords"
			:rowsPerPageOptions="[100, 200, 500, 1000]" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
			@filter="onFilter($event)" filterDisplay="row" responsiveLayout="scroll">


			<Column field="id" header="Id" filterField="id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['id']}}
					</div>
				</template>
			</Column>
			<Column field="name" header="Name" filterField="name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						<b>{{ data['name']}}</b>
					</div>
				</template>
			</Column>
			<Column field="email" header="Email" filterField="email" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Email" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['email']}}
					</div>
				</template>
			</Column>
			<!-- <Column field="password" header="Password" filterField="password" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Password" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['password']}}
					</div>
				</template>
			</Column> -->
			<Column field="phone" header="Phone" filterField="phone" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Phone" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['phone']}}
					</div>
				</template>
			</Column>
			<Column field="is_staff" header="Is Staff" filterField="is_staff" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Y/N" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['is_staff']}}
					</div>
				</template>
			</Column>
			<!-- <Column field="address" header="Address" filterField="address" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Address" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['address']}}
					</div>
				</template>
			</Column> -->
			<!-- <Column field="bank_details" header="Bank Details" filterField="bank_details" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Bank Details" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['bank_details']}}
					</div>
				</template>
			</Column> -->
			<Column field="created_at" header="Created At" filterField="created_at" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Created At" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['created_at']}}
					</div>
				</template>
			</Column>
			<Column field="due_amount" header="Due Amount" filterField="due_amount" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Due Amount" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						&#8377;{{ data['due_amount'] }}
					</div>
				</template>
			</Column>
			<Column field="last_month_dues" header="Last Month Dues" filterField="last_month_dues" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Last Month Dues" />
					</div>
				</template>
				<template #body="{ data }">
					<div v-if="data['is_staff'] == 'n'" class="tdata text-center">
						<div>
							&#8377;{{ data['last_month_dues'] }}
						</div>
						<!-- <div v-if="data['last_month_dues'] && data.last_month_paid == 'n'">
							<i @click="openPaymentPopup(data)" style="font-size: 30px; cursor: pointer;" class="fa-solid fa-money-check-dollar text-success"></i>
						</div> -->
					</div>
				</template>
			</Column>
			<Column
				header="Options &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;">
				<template #body="{ data }">
					<div class="tdata options">
						<button class="btn btn-sm btn-outline-success" @click="login(data)"><i
								class="fa fa-unlock-alt"></i></button>
						<router-link v-if="data['is_staff'] == 'n'" :to="'agent/payment/' + data['id']">
							<button class="btn btn-sm btn-outline-primary"><i class="fa fa-money"></i></button>
						</router-link>
						<button class="btn btn-sm btn-outline-warning" @click="showAddEdit(data)"><i
								class="fa fa-edit"></i></button>
						<button class="btn btn-sm btn-outline-danger" @click="editId = data['id']; deleteModal.show();"><i
								class="fa fa-trash"></i></button>
					</div>
				</template>
			</Column>
		</DataTable>

		<div class="modal fade" ref="deleteModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-danger text-white">
						<h5 class="modal-title">Delete Confirmation</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body bg-white text-body text-center font-weight-bold font-1xl">
						<div class="text-danger" style="font-weight: bold; text-align: left; margin-bottom: 8px;">Record ID:
							{{ editId }}</div>
						You are about to delete this record, Are you sure?
						<br>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-danger" @click="deleteRecord">Delete</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" ref="payModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-info text-white">
						<h5 class="modal-title">Make Payment To {{ editRow?.name }}</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">

						<div class="form-group">
							<label>Amount</label>
							<input type="number" v-model="pay.amount" class="form-control">
						</div>
						<div class="form-group">
							<label>Mode</label>
							<select v-model="pay.mode" class="form-control">
								<option value="" disabled>Select Payemnt Mode</option>
								<option>NEFT</option>
								<option>Cash</option>
								<option>Paytm</option>
								<option>GPay</option>
								<option>PhonePe</option>
								<option>UPI</option>
							</select>
						</div>
						<div class="form-group">
							<label>Transaction ID</label>
							<input type="text" v-model="pay.transaction_id" class="form-control">
						</div>
						<div class="form-group p-fluid">
							<label>Payment Date</label>
							<Calendar placeholder="Payment Date" v-model="pay.created_at" dateFormat='dd/mm/yy' />
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-info" @click="makePayment">Confirm Payment</button>
					</div>
				</div>
			</div>
		</div>

		<form action="" @submit.prevent="putRecord">
			<div class="modal fade" id="exampleModal" ref="addModal" tabindex="-1" aria-labelledby="exampleModalLabel"
				aria-hidden="true">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel">
								<div v-if="editId == 0">Add Agent</div>
								<div v-else>Edit Agent</div>
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">


							<div class="form-group ui-float-label">
								<input type="text" class="form-control"
									:class="{ 'is-invalid': fcontrol.name?.hasError && fcontrol.name.touched }"
									v-model="fdata.name" @blur="fcontrol.name.touched = true" placeholder=" " />
								<div class="invalid-feedback" v-if="fcontrol.name?.hasError">
									<span v-if="fcontrol.name.errors.required">You must enter name</span>
									<span v-if="fcontrol.name.errors.max">Name can contain maximum
										{{ fcontrol.name.errors.max.maxLength }} characters</span>
								</div>
								<label>Name</label>
							</div>
							<div class="form-group ui-float-label">
								<input type="text" class="form-control"
									:class="{ 'is-invalid': fcontrol.email?.hasError && fcontrol.email.touched }"
									v-model="fdata.email" @blur="fcontrol.email.touched = true" placeholder=" " />
								<div class="invalid-feedback" v-if="fcontrol.email?.hasError">
									<span v-if="fcontrol.email.errors.required">You must enter email</span>
									<span v-if="fcontrol.email.errors.max">Email can contain maximum
										{{ fcontrol.email.errors.max.maxLength }} characters</span>
								</div>
								<label>Email</label>
							</div>
							<div class="form-group ui-float-label">
								<input type="text" class="form-control"
									:class="{ 'is-invalid': fcontrol.password?.hasError && fcontrol.password.touched }"
									v-model="fdata.password" @blur="fcontrol.password.touched = true" placeholder=" " />
								<div class="invalid-feedback" v-if="fcontrol.password?.hasError">
									<span v-if="fcontrol.password.errors.required">You must enter password</span>
									<span v-if="fcontrol.password.errors.max">Password can contain maximum
										{{ fcontrol.password.errors.max.maxLength }} characters</span>
								</div>
								<label>Password</label>
							</div>
							<div class="form-group ui-float-label">
								<input type="text" class="form-control"
									:class="{ 'is-invalid': fcontrol.phone?.hasError && fcontrol.phone.touched }"
									v-model="fdata.phone" @blur="fcontrol.phone.touched = true" placeholder=" " />
								<div class="invalid-feedback" v-if="fcontrol.phone?.hasError">
									<span v-if="fcontrol.phone.errors.required">You must enter phone</span>
									<span v-if="fcontrol.phone.errors.max">Phone can contain maximum
										{{ fcontrol.phone.errors.max.maxLength }} characters</span>
								</div>
								<label>Phone</label>
							</div>
							<div class="form-group ui-float-label">
								<input type="text" class="form-control"
									:class="{ 'is-invalid': fcontrol.address?.hasError && fcontrol.address.touched }"
									v-model="fdata.address" @blur="fcontrol.address.touched = true" placeholder=" " />
								<div class="invalid-feedback" v-if="fcontrol.address?.hasError">
									<span v-if="fcontrol.address.errors.required">You must enter address</span>
								</div>
								<label>Address</label>
							</div>
							<div class="form-group ui-float-label">
								<select class="form-control"
									:class="{ 'is-invalid': fcontrol.is_staff?.hasError && fcontrol.is_staff.touched }"
									v-model="fdata.is_staff" @blur="fcontrol.is_staff.touched = true">
									<option value="y">Yes</option>
									<option value="n">No</option>
								</select>
								<div class="invalid-feedback" v-if="fcontrol.is_staff?.hasError">
									<span v-if="fcontrol.is_staff.errors.required">You must select is_staff</span>
								</div>
								<label>Is Staff</label>
							</div>
							<div class="form-group ui-float-label">
								<textarea class="form-control"
									:class="{ 'is-invalid': fcontrol.bank_details?.hasError && fcontrol.bank_details.touched }"
									v-model="fdata.bank_details" @blur="fcontrol.bank_details.touched = true"
									placeholder=" "></textarea>
								<div class="invalid-feedback" v-if="fcontrol.bank_details?.hasError">
									<span v-if="fcontrol.bank_details.errors.required">You must enter bank details</span>
								</div>
								<label>Bank Details</label>
							</div>

						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="submit" class="btn btn-primary">Save</button>
						</div>
					</div>
				</div>
			</div>
		</form>

	</div>
</template>

<script>
import { Modal } from 'bootstrap'
import Calendar from 'primevue/calendar';
export default {
	components: {
		Calendar,
	},
	data() {
		return {
			pay: {
				agent_id: 0,
				amount: '',
				mode: '',
				transaction_id: '',
				created_at: null,
			},

			loading: false,
			totalRecords: 0,
			tableRecords: null,
			editId: 0,
			editRow: null,
			saving: false,
			addModal: null,
			deleteModal: null,
			payModal: null,
			lazyParams: {},
			filters: {
				'id': { value: '', matchMode: 'like' },
				'name': { value: '', matchMode: 'like' },
				'email': { value: '', matchMode: 'like' },
				'password': { value: '', matchMode: 'like' },
				'phone': { value: '', matchMode: 'like' },
				'address': { value: '', matchMode: 'like' },
				'is_staff': { value: '', matchMode: 'like' },
				'bank_details': { value: '', matchMode: 'like' },
				'due_amount': { value: '', matchMode: 'like' },
				'last_month_dues': { value: '', matchMode: 'like' },
				'created_at': { value: '', matchMode: 'like' },
			},
			fdata: {
				id: '',
				name: '',
				email: '',
				password: '',
				phone: '',
				address: '',
				is_staff: 'n',
				bank_details: '',
			},
			fvalidator: {
				name: 'required|max:255',
				email: 'required|max:255',
				password: 'required|max:255',
				phone: 'required|max:20',
				address: 'required',
				is_staff: 'required',
				bank_details: 'required',
			}
		}
	},
	mounted() {
		this.addModal = new Modal(this.$refs.addModal);
		this.deleteModal = new Modal(this.$refs.deleteModal);
		this.payModal = new Modal(this.$refs.payModal);

		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters,
		};

		// this.post("admin/calculate-agent-last-month-due", {

		// }).then(res=>{
		// });
		this.loadLazyData();
	},
	methods: {
		openPaymentPopup(data){
			this.editRow=data;

			this.pay.agent_id = data.id,
			this.pay.amount = data.last_month_dues;
			this.pay.mode = '';
			this.pay.transaction_id = '';
			this.pay.created_at = new Date();

			this.payModal.show();
		},
		makePayment(){
			if(this.saving) return;
			if(!this.pay.amount) return;
			let fd = {
				agent_id: this.pay.agent_id,
				amount: this.pay.amount,
				mode: this.pay.mode,
				transaction_id: this.pay.transaction_id,
				created_at: this.format(this.pay.created_at, "YYYY-MM-DD HH:mm:ss")
			}
			this.saving = true;

			this.post("admin/make-agent-monthly-payment", fd).then(res => {
				this.saving = false;
				if(res.success){
					this.loadLazyData();
					this.payModal.hide();
				}
			});
		},

		login(data){
			localStorage.agent_access_token = JSON.stringify(data);
			window.open('/agent/xray-reports', "_blank").focus();
		},
		putRecord() {
			if (this.fHasError() || this.saving) return;
			this.saving = true;


			if (this.editId) this.fdata.id = this.editId;
			this.post("admin/put-agent", this.fdata).then(res => {
				this.saving = false;
				if (res.success) {
					this.addModal.hide();
					this.loadLazyData();
				}
			});
		},
		searchDoctor(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredDoctors = [...this.customers];
				} else {
					this.filteredDoctors = this.customers.filter((country) => {
						return country.name.toLowerCase().startsWith(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		showAddEdit(row) {
			this.freset();
			this.editId = row ? row.id : 0;
			if (row) this.fpopulate(row);
			this.addModal.show();
		},
		deleteRecord() {
			this.delete("admin/delete-agent/" + this.editId).then(res => {
				this.saving = false;
				if (res.success) {
					this.deleteModal.hide();
					this.loadLazyData();
				}
			});
		},
		loadLazyData() {
			this.loading = true;
			let params = JSON.parse(JSON.stringify(this.lazyParams));
			for (let f in params.filters) {
				if (!params.filters[f].value.length) delete params.filters[f];
			}

			if (!params.sortField) {
				params.sortField = "id";
				params.sortOrder = -1;
			}
			this.post("admin/load-agents", params).then(res => {
				this.loading = false;
				if (res.success) {
					this.totalRecords = res.totalRecords;
					this.tableRecords = res.rows;
				}
			});
		},
		onPage(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onSort(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onFilter() {
			this.loading = true;
			this.lazyParams.filters = this.filters;
			this.loadLazyData();
		}
	}
}
</script>
<style scoped>

</style>